export interface SerializedPhoneNetwork {
    id: string;
    name: string;
    logo: string;
}

export default class PhoneNetwork {
    private readonly _id: string;
    private readonly _name: string;
    private readonly _logo: string;

    constructor(network: SerializedPhoneNetwork) {
        this._id = network.id;
        this._name = network.name;
        this._logo = network.logo;
    }

    get id(): string {
        return this._id;
    }

    get name(): string {
        return this._name;
    }

    get logo(): string {
        return this._logo;
    }
}
